const GitLogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    font-family="Verdana"
  >
    <rect width="150" height="150" x="25" y="25" rx="20" fill="#E94F36" />
    <rect width="80" height="80" x="60" y="60" rx="20" fill="white" />
    <text
      x="50%"
      y="52%"
      dominant-baseline="middle"
      text-anchor="middle"
      fill="black"
      font-size="38"
    >
      Git
    </text>
  </svg>
);

export default GitLogo;
