const CLILogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    font-family="Verdana"
  >
    <circle cx="100" cy="100" r="98" fill="black" />
    <text
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      fontSize="38"
      x="45%"
      y="25%"
    >
      &gt;_
    </text>
    <text
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      font-size="38"
      x="50%"
      y="52%"
    >
      Terminal
    </text>
  </svg>
);

export default CLILogo;
