const JiraLogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    font-family="Verdana"
  >
    <rect
      width="150"
      height="150"
      x="25"
      y="25"
      rx="20"
      fill="#2980B9"
      transform="rotate(45, 100, 100)"
    />
    <rect
      width="90"
      height="90"
      x="55"
      y="55"
      rx="20"
      fill="white"
      transform="rotate(45, 100, 100)"
    />
    <text
      x="50%"
      y="52%"
      dominant-baseline="middle"
      text-anchor="middle"
      fill="#2980B9"
      font-size="38"
    >
      Jira
    </text>
  </svg>
);

export default JiraLogo;
