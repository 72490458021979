const experiences = [
    {
        _id: 1,
        role: "Grad Developer (Rotation 3)",
        organisation: "Te Tāhuhu o te Mātauranga (MoE)",
        image: "https://www.education.govt.nz/_next/static/media/te-tahuhu-logo-purple.22b79467.svg",
        duration: "Jun 2024 - Present",
        url: "https://www.education.govt.nz/",
        paragraph: "As a member of the Web Practice team, I:",
        description: "Collaborated with developers to add and improve UI features on education.govt.nz website. Added \"latest news\" side panel component, content reporting CMS views, resolved styling tasks or character input adjustment. Collaborated with cross-functional teams BA, web advisors and product managers to deliver user-centric solutions using agile methodologies and sprints.",
        tools: "Tools: Drupal, NextJS, CSS, PHP, Git, VS Code, Terminal CLI, Docker, Jira board, Confluence."
    },
    {
        _id: 2,
        role: "Grad Developer (Rotation 2)",
        organisation: "Te Tari Taiwhenua (DIA)",
        duration: "Oct 2023 to Jun 2024",
        url: "https://www.dia.govt.nz/",
        paragraph: "In the Digital Development and Testing Services team, I:",
        description: "Contributed to the maintenance and improvement of critical government digital platforms like the Gazette.govt.nz, and Govt.nz websites. Supported the accessibility and mobile friendliness of the Gazette “advanced search”. Participated in Agile ceremonies, using the team's Jira board to manage our sprints and played a key role in resolving some technical issues.",
        tools: "Tools: Silverstripe, PHP, CSS, Cucumber and JS for Selenium and Playwright for UX testing, Git, GitHub, VS Code, Terminal CLI, Jira board, Confluence."
    },
    {
        _id: 3,
        role: "Grad Developer (Rotation 1)",
        organisation: "WorkSafe NZ",
        duration: "Feb to Oct - 2023",
        url: "https://www.worksafe.govt.nz/",
        paragraph: "Within the Intervention Management System in the Digital Transformation team, I:",
        description: "Implemented  changes and substituting business rules with JavaScript functions uploaded to PowerApps as web resources in the library. Collaborated with Dynamics365 and ADO to enhance Atlas, WorkSafe's incident notification system, by updating JS snippets for Portal and adjusted submission forms in PowerApps. Participated in UAT sessions to enhance UX for Portal customers and the Agency’s triage team.",
        tools: "Tools: PowerApps, DataVerse, Git version control, PPDO custom tool, ADO board."
    },
    {
        _id: 4,
        role: "ITSM Intern",
        organisation: "Sentify Wellington",
        duration: "Mar to Jul - 2022",
        url: "https://sentify.co/",
        paragraph: "Within the delivery and technical support team, I:",
        description: "Updated node package manager dependencies in clients project repository. Maintained the Confluence documentation up-to-date to facilitate hand-over for future developers hired by the clients.",
        tools: "Tools: NodeJS, NPM, Git, GitHub, VS Code, Terminal CLI, Jira board, Confluence."
    },
];

export default experiences;
