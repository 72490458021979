const DockerLogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    font-family="Verdana"
  >
    <path
      d="M30,130 C50,90 150,90 170,130"
      stroke="white"
      strokeWidth="12"
      strokeLinecap="round"
    />
    <path
      d="M40,130 C60,90 140,90 160,130"
      stroke="white"
      strokeWidth="12"
      strokeLinecap="round"
    />
    <path
      d="M50,130 C70,90 130,90 150,130"
      stroke="white"
      strokeWidth="12"
      strokeLinecap="round"
    />
    <circle cx="100" cy="100" r="95" fill="#2496ED" />
    <rect x="60" y="50" width="20" height="30" fill="white" rx="3" />
    <rect x="90" y="50" width="20" height="30" fill="white" rx="3" />
    <rect x="120" y="50" width="20" height="30" fill="white" rx="3" />
    <rect x="60" y="125" width="20" height="30" fill="white" rx="3" />
    <rect x="90" y="125" width="20" height="30" fill="white" rx="3" />
    <rect x="120" y="125" width="20" height="30" fill="white" rx="3" />
    <text
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      font-size="38"
      x="50%"
      y="52%"
    >
      Docker
    </text>
  </svg>
);

export default DockerLogo;
