import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CLILogo from "../assets/logos/cli.jsx";
import DockerLogo from "../assets/logos/docker.jsx";
import FigmaLogo from "../assets/logos/figma.jsx";
import GitLogo from "../assets/logos/git.jsx";
import JiraLogo from "../assets/logos/jira.jsx";
import VSCodeLogo from "../assets/logos/vscode.jsx";
import styles from "../components/about.module.css";

const About = () => {
  const translations = ["Welcome", "Nau mai", "Bienvenue", "Iaorana"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % translations.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [translations.length]);

  return (
    <>
      <h1 className={`${styles.title} ${styles.fade}`}>
        {translations[currentIndex]}
      </h1>
      <Container className={styles.introCard}>
        <Row className="justify-content-md-center" gap={3}>
          <Col md="auto" className="mb-3">
            <img
              src="/assets/thumbnails/profile-avatar.jpeg"
              alt="profile"
              className={styles.avatar}
            ></img>
          </Col>
          <Col lg="auto">
            <h2 className="text-center mb-4">I'm Tehaunui</h2>
            <Card className={styles.introDescription}>
              <p className="text-center p-3 m-1">
                Currently a web developer at the Ministry of Education. I enjoy
                being involved in front-end, back-end development, and build
                intuitive web apps, websites and dev related projects. I'm
                fluent in both{" "}
                <img
                  width="20px"
                  src="/assets/logos/french-flag.png"
                  alt="French-flag"
                />{" "}
                French and{" "}
                <img
                  width="20px"
                  src="/assets/logos/british-flag.png"
                  alt="United-Kingdom-flag"
                />{" "}
                English, and intermediate level in{" "}
                <img
                  width="20px"
                  src="/assets/logos/spanish-flag.png"
                  alt="Spanish-flag"
                />{" "}
                Spanish.
              </p>
            </Card>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <h3>Skills</h3>
        </div>
        <Card>
          <Row className="justify-content-center" xs sm md lg={12}>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img src="/assets/logos/html-5.png" alt="HTML-logo"></img>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img src="/assets/logos/css-3.png" alt="CSS-logo"></img>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img src="/assets/logos/js.png" alt="JavaScript-logo"></img>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img
                src="/assets/logos/typescript.png"
                alt="Typescript-logo"
              ></img>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img src="/assets/logos/react.png" alt="React-logo"></img>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img src="/assets/logos/node-js.png" alt="NodeJS-logo"></img>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <img src="/assets/logos/php.png" alt="PHP-logo"></img>
            </Col>
          </Row>
        </Card>
        <div className="text-center mt-5">
          <h3>Tools</h3>
        </div>
        <Card>
          <Row className="justify-content-evenly" xs sm md lg={12}>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <CLILogo alt="CLI-logo"></CLILogo>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <DockerLogo alt="Docker-logo"></DockerLogo>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <GitLogo alt="Git-logo"></GitLogo>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <JiraLogo alt="Jira-logo"></JiraLogo>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <FigmaLogo alt="Figma-logo"></FigmaLogo>
            </Col>
            <Col xs md lg className="d-flex justify-content-center m-3">
              <VSCodeLogo alt="Figma-logo"></VSCodeLogo>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default About;
