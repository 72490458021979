const FigmaLogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    font-family="Verdana"
  >
    <defs>
      <linearGradient id="grad1">
        <stop offset="0%" stop-color="red" />
        <stop offset="20%" stop-color="green" />
        <stop offset="40%" stop-color="blue" />
        <stop offset="90%" stop-color="purple" />
      </linearGradient>
    </defs>
    <circle cx="100" cy="100" r="95" fill="black" />
    <circle cx="70" cy="50" r="25" fill="red" />{" "}
    <circle cx="70" cy="102" r="25" fill="green" />{" "}
    <circle cx="70" cy="155" r="25" fill="blue" />{" "}
    <circle cx="130" cy="50" r="25" fill="red" />{" "}
    <circle cx="130" cy="102" r="25" fill="purple" />{" "}
    <text
      x="50%"
      y="52%"
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      font-size="38"
    >
      Figma
    </text>
  </svg>
);

export default FigmaLogo;
