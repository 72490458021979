const VSCodeLogo = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    font-family="Verdana"
  >
    <defs>
      <linearGradient id="grad1">
        <stop offset="0%" stop-color="black" />
        <stop offset="100%" stop-color="blue" />
      </linearGradient>
    </defs>
    <rect
      width="150"
      height="150"
      x="25"
      y="25"
      rx="20"
      fill="#2980B9"
      transform="rotate(45, 100, 100)"
    />
    <rect
      width="90"
      height="90"
      x="55"
      y="55"
      rx="20"
      fill="black"
      transform="rotate(45, 100, 100)"
    />
    <rect
      width="50"
      height="50"
      x="75"
      y="75"
      rx="20"
      fill="#2980B9"
      transform="rotate(45, 100, 100)"
    />
    <text
      dominant-baseline="middle"
      text-anchor="middle"
      fill="white"
      font-size="38"
      x="50%"
      y="52%"
    >
      VSCode
    </text>
  </svg>
);

export default VSCodeLogo;
